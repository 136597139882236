import { useItemContext } from 'context/item-context';
import { ChangeEvent, useEffect, useState } from 'react';
import { Item, ItemSelection, Pagination } from 'types';
import usePagination from './usePagination';
import useSearch from './useSearch';

/**
 * Use the Item API to paginate through an list of passed items with vendorItemIds
 * @param itemSelection An array of currently selected/assigned items with vendorItemIds
 * @returns An array of items from the Items API
 */
const usePaginatedProducts = (
  itemSelection?: ItemSelection[]
): {
  products: Item[];
  pagination: Pagination;
  handleChangePage: (event: ChangeEvent<unknown>, page: number) => void;
  handleChangePageSize: (event: ChangeEvent<unknown>) => void;
  handleSearch: (searchItems: { id: string; value: string }[]) => void;
  loading: boolean;
  resetPagination: () => void;
  handleClearSearch: () => void;
} => {
  const {
    pagination,
    handleChangePage,
    handleChangePageSize,
    setInitialPaginationState,
    resetPagination,
  } = usePagination();
  const { handleSearch, handleClearSearch, searchItems } = useSearch(resetPagination);
  const {
    state: { items, status },
  } = useItemContext();
  const { pageSize, page } = pagination;
  const loading = status === 'loading';
  const [products, setProducts] = useState<Item[]>([]);
  // TODO: Handle reset to page one if new page size does not include current page
  useEffect(() => {
    let filteredItems = Object.values(items);
    if (searchItems.length) {
      filteredItems = Object.keys(items)
        .filter((key) => searchItems.includes(key))
        .map((key) => items[key]);
    }
    if (Array.isArray(itemSelection)) {
      filteredItems = itemSelection.map(({ sortOrder, vendorItemId }) => ({
        ...items[vendorItemId],
        vendorItemId,
        sortOrder,
      }));
    }
    const itemArray = filteredItems;
    const totalPages = Math.ceil(itemArray.length / pageSize) || 1;
    // On page size change, if new page is out of range, reset to next lowest page (zero-indexed pages)
    const newPage = page >= totalPages ? totalPages - 1 : page;
    setInitialPaginationState({
      totalElements: itemArray.length,
      totalPages,
      page: newPage,
      pageSize,
    });
    setProducts(itemArray.slice(newPage * pageSize, newPage * pageSize + pageSize));
  }, [items, itemSelection, page, pageSize, searchItems, setInitialPaginationState]);
  return {
    products,
    handleChangePage,
    handleChangePageSize,
    pagination,
    handleSearch,
    resetPagination,
    handleClearSearch,
    loading,
  };
};

export default usePaginatedProducts;
