import React, { createContext, Dispatch, useContext, useReducer } from 'react';

export type ToastState = {
  show: boolean;
  severity: 'success' | 'info' | 'warning' | 'error';
  text: string;
};

interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

type ToastActions = Action;

const initialState: ToastState = {
  show: false,
  severity: 'info',
  text: '',
};

const ToastContext = createContext<{ state: ToastState; dispatch: Dispatch<ToastActions> }>({
  state: {} as ToastState,
  dispatch: () => undefined,
});
ToastContext.displayName = 'ToastContext';

const toastReducer = (state: ToastState, action: ToastActions): ToastState => {
  const { type, payload } = action;
  switch (type) {
    case 'show toast': {
      return {
        show: true,
        severity: payload.severity,
        text: payload.text,
      };
    }
    case 'hide toast': {
      return {
        ...state,
        show: false,
      };
    }
    default:
      return state;
  }
};

const ToastProvider = (props: { children: JSX.Element }): JSX.Element => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const { children } = props;
  return <ToastContext.Provider value={{ state, dispatch }}>{children}</ToastContext.Provider>;
};

const useToastContext = (): { state: ToastState; dispatch: Dispatch<ToastActions> } => {
  return useContext(ToastContext);
};

const showToast = (dispatch: Dispatch<Action>, toast: { severity: string; text: string }): void => {
  dispatch({ type: 'show toast', payload: toast });
};

const hideToast = (dispatch: Dispatch<Action>): void => {
  dispatch({ type: 'hide toast' });
};

export { hideToast, showToast, ToastContext, ToastProvider, toastReducer, useToastContext };
