import { useCategoryContext } from 'context/CategoryContext';
import { resetNewCategoryFields, useNewCategoryContext } from 'context/new-category';
import { showToast, useToastContext } from 'context/toast-context';
import { useZoneContext } from 'context/zone-context';
import config from 'countries.config.json';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';
import useZoneLanguages from 'hooks/useZoneLanguages';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import getSortOrder from 'utils/sortOrder/getSortOrder';
import useCategoryService from './useCategoryService';

const useNewCategory = (): {
  isConfirmModalOpen: boolean;
  handleOpenConfirmModal: () => void;
  handleCloseConfirmModal: () => void;
  handleDiscardClick: () => void;
  handleCreateClick: () => void;
  isModalOpen: boolean;
  handleConfirmClick: () => void;
  handleCancelClick: () => void;
  handleOpenModal: () => void;
  handleCloseModal: () => void;
  handleBlockedNavigation: (location: Location) => boolean;
  showDiscardModal: boolean;
  success: boolean;
  handleSave: () => void;
  isSaving: boolean;
} => {
  const { dispatch, state } = useNewCategoryContext();
  const { acceptedLanguages: acceptedZoneLanguages } = useZoneLanguages();
  const {
    state: { categories },
  } = useCategoryContext();
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const { createCategory, getAllCategories } = useCategoryService();
  const {
    state: { country },
  } = useZoneContext();
  const { success } = state.status;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { dispatch: toastDispatch } = useToastContext();

  const history = useHistory();

  useEffect(() => {
    if (confirmedNavigation && nextLocation) {
      // Navigate to the previous blocked location
      resetNewCategoryFields(dispatch);
      history.push(nextLocation?.pathname as string);
    }
  }, [confirmedNavigation, nextLocation, history, dispatch]);

  useEffect(() => {
    async function handleSuccess() {
      // Get updated category data after new category creation
      await getAllCategories();
      setIsSaving(false);
      history.push('/browse-category');
      const createdMultipleCategories: boolean =
        (state.parentCategories.length > 2 && acceptedZoneLanguages.length > 1) ||
        (state.parentCategories.length >= 2 && acceptedZoneLanguages.length === 1);
      showToast(toastDispatch, {
        severity: 'success',
        text: createdMultipleCategories
          ? 'All good! You have created new categories.'
          : 'All good! You have created a new category.',
      });
    }
    if (success) {
      handleSuccess();
    }
  }, [success, history, getAllCategories, toastDispatch, state, acceptedZoneLanguages]);
  useEffect(() => {
    return () => {
      resetNewCategoryFields(dispatch);
    };
  }, [dispatch]);

  const handleBlockedNavigation = (location: Location<unknown>): boolean => {
    if (!confirmedNavigation) {
      setNextLocation(location);
      setShowDiscardModal(true);
      return false;
    }
    return true;
  };

  const handleDiscardClick = () => {
    history.push('/browse-category');
  };

  const handleConfirmClick = () => {
    setConfirmedNavigation(true);
  };

  const handleCancelClick = () => {
    setShowDiscardModal(false);
  };

  const handleValidate = () => {
    const { categoryLevel, position } = state;
    let sortOrder = 0;
    if (categoryLevel === 1 && Object.values(categories).length) {
      sortOrder = getSortOrder(Object.values(categories), position);
    }
    const { acceptedLanguages } = config[country];
    const category = { ...state, sortOrder };
    let hasInputError = false;
    _.each(_.keys(category), (field: string) => {
      switch (field) {
        case 'categoryNames': {
          const nameFields = Object.values(category.categoryNames).map((name) => name.trim());
          // Checks to see if any of the name fields are empty
          if (nameFields.length !== acceptedLanguages.length || nameFields.includes('')) {
            dispatch({
              type: 'empty field error',
              payload: { error: 'categoryNames' },
            });
            hasInputError = true;
          }
          break;
        }
        case 'categoryLevel': {
          if (category[field] > 1 && !category.parentCategories.length) {
            dispatch({
              type: 'empty field error',
              payload: { error: 'parentCategories' },
            });
            hasInputError = true;
          }
          break;
        }
        case 'categoryType': {
          if (category[field] === 'premium' && !category.premium.length) {
            dispatch({
              type: 'empty field error',
              payload: { error: 'premium' },
            });
            hasInputError = true;
          }
          break;
        }
        default:
      }
    });
    if (hasInputError) {
      handleCloseConfirmModal();
    } else {
      handleCloseModal();
      handleOpenConfirmModal();
    }
  };

  const handleCreateClick = () => {
    handleValidate();
  };

  const handleSave = () => {
    handleCloseModal();
    handleCloseConfirmModal();
    setIsSaving(true);
    createCategory();
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleOpenConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };
  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };
  return {
    isConfirmModalOpen,
    handleOpenConfirmModal,
    handleCloseConfirmModal,
    handleDiscardClick,
    handleCreateClick,
    isModalOpen,
    handleCloseModal,
    handleOpenModal,
    showDiscardModal,
    handleCancelClick,
    handleConfirmClick,
    handleBlockedNavigation,
    success,
    handleSave,
    isSaving,
  };
};

export default useNewCategory;
